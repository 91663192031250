<template>
  <div class="mt-4">
    <div class="vx-row">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>

    <!--File List-->
    <vs-divider class="m-0">Upload File Pengajuan Pembayaran Termin dari Rekanan (jika ada)</vs-divider>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2">Add</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <vs-table :data="files" stripe class="fix-table-input-background" :class="{ 'animate-pulse': isLoadingPoFiles }">
          <template slot="thead">
            <vs-th class="whitespace-no-wrap" v-if="isAnyUnsavedFiles">Action</vs-th>
            <vs-th class="whitespace-no-wrap">#</vs-th>
            <vs-th class="whitespace-no-wrap">No Termin</vs-th>
            <vs-th class="whitespace-no-wrap">Nama File</vs-th>
            <vs-th class="whitespace-no-wrap">Lihat File</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td v-if="isAnyUnsavedFiles">
                <vs-button v-if="item.isUnsaved" color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
              </vs-td>
              <vs-td>{{ index + 1 }}</vs-td>
              <vs-td>{{ item.no_termin ? 'Termin ' + item.no_termin : '' }}</vs-td>
              <vs-td>
                <vs-input v-if="item.isUnsaved" v-model="item.nama" type="text"/>
                <span v-else>{{ item.nama }}</span>
              </vs-td>
              <vs-td>
                <vs-input v-if="item.isUnsaved" class="w-full" type="file" accept=".jpeg,.jpg,.png,.pdf" v-model="item.fileTmp" @change="item.file = $event.target.files[0]"/>
                <a v-else :href="item.file_url" target="_blank">
                  <vs-chip color="primary"><vs-avatar icon-pack="feather" icon="icon-file"/><span>File</span></vs-chip>
                </a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" icon-pack="feather" icon="icon-arrow-left" class="mr-3">Prev</vs-button>
        <vs-button :disabled="isLoading" @click="save" icon-pack="feather" icon="icon-save" color="success">
          <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
          <span v-if="!isLoading">Simpan</span>
        </vs-button>
      </div>
    </div>

  </div>
</template>

<script>
import PoTerminApprovalRepository from '@/repositories/approvals/po-termin-approval-repository'
import PoRepository from '@/repositories/proyek/po-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'TabAkunting',
  props: ['isActive', 'idPo', 'idPoD', 'poD'],
  components: {
    ValidationErrors
  },
  watch: {
    isActive (active) {
      if (active && this.isFirstActive) {
        this.getPoFiles()
        this.isFirstActive = false
      }
    }
  },
  computed: {
    isAnyUnsavedFiles () {
      return _.filter(this.files, item => item.isUnsaved).length > 0
    }
  },
  data () {
    return {
      isFirstActive: true,
      isLoading: false,
      isLoadingPoFiles: false,
      errors: null,
      data: {},
      files: []
    }
  },
  methods: {
    getPoFiles () {
      this.isLoadingPoFiles = true

      PoRepository.getPoFiles(this.idPo)
        .then(response => {
          this.files = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoadingPoFiles = false
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData({
        id_po_d: this.idPoD,
        files: _.filter(this.files, item => item.isUnsaved)
      })
      PoTerminApprovalRepository.saveTabAkunting(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.getPoFiles()
      this.notifySuccess('Data berhasil disimpan.')
    },

    addRow () {
      const row = { uuid: uuid(), isUnsaved: true }
      this.files.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.files, item => item.uuid === uuid)
      this.files.splice(index, 1)
    },

    prev () {
      const currentActiveTab = this.$store.state.approvals.poTerminApproval.activeTab
      this.$store.commit('approvals/poTerminApproval/SET_ACTIVE_TAB', currentActiveTab - 1)
    }
  }
}
</script>
